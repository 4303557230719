.login-logo{
  width: 40%;
}

.header-logo{
  width: 10%;
}

.modal-footer-logo{
  width: 5%;
}

.text-header{
  color: #417b6d;
  font-size: 1rem;
}

.text-header-logo{
  font-family: 'Balthazar', serif;
  color: #36665a;
  font-size: 1.5rem;
}

.btn-user-header{
  color: #36665a;
  border-color: transparent !important;
}

.pro-sidebar-inner, .pro-inner-list-item, .popper-inner{
  background: #fff !important;
  /*border-right: 1px solid rgb(173, 173, 173, 0.2);*/
}

.pro-icon-wrapper{
  background: #59a793 !important;
  border-right: 1px solid rgb(173, 173, 173, 0.2);
}

.pro-icon:hover{
  color: #fff !important;
}

.pro-sidebar{
  color: #ffffff !important;
}

.prosidebar-submenu-item:hover{
  color: #9b9797;
}

.prosidebar-footer-text{
  font-size: 0.6rem;
  color:  #36665a;
}

.capitalize{
  text-transform: capitalize;
}

.btn-outline-primary{
  border-color: #36665a;
  color: #36665a;
}

.btn-outline-primary:hover{
  border-color: #36665a;
  background-color: #36665a;
}

.btn-outline-primary.disabled,.btn-outline-primary:disabled{
  color:#36665a;
  background-color:transparent
}

.input-group-text{
  color: #fff;
  background-color: #59a793;
  border: 1px solid #36665a;
}

.form-control{
  border: 1px solid #36665a;
}

.login-footer{
  background-color: rgb(37, 47, 61);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
}

.footer-login-text{
  color: #fff !important;
}

.text-primary {
  color: #59a793 !important;
}

/*Material-UI rules*/
.MuiFilledInput-root{
  background-color: transparent !important;
}

.MuiChip-colorPrimary{
  background-color: #36665a !important;
}

.MuiChip-root .MuiChip-avatarColorPrimary {
  color: #fff;
  background-color: #59a793 !important;
}

.MuiChip-outlinedPrimary {
  color: #36665a !important;
  border: 1px solid #36665a !important;
  background-color: transparent !important;
}

.Cell-highlightedText-58 {
  color: #36665a !important;
}

.MuiChip-deleteIconOutlinedColorPrimary {
  color: #36665a !important;
}
/**/

/*Editor wysiwyg*/
.text-editor{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.editor-border{
  border: 1px solid #ced4da
}
/**/

.action-button{
  padding: 0 !important;
}